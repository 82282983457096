import { useEffect, useState,useContext, Fragment, useRef } from 'react';
import { Row,Col} from 'react-bootstrap';
import { reqtsApiForm } from '../../config/utils';
import { MenuContext } from '../../context/MenuContext';
import ReactSelect from 'react-select'
import { Scheduler } from "@aldabil/react-scheduler";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { Button,Typography } from "@mui/material";
import { API_BASE } from '../../config/enviroment';


/**
    * Generic task async
    * @param {*} route 
    * @param {*} type 
    * @param {*} params 
    * @returns 
    */
async function GeneralRequest(route, type, params) {
    return reqtsApiForm(route, true, type, params, data => data, error => error)
        .then(resp => resp)
        .catch(
            err => {
                console.log(err);
            }
        );
}


export default function SchedulerProf(){

    const { colorTheme,setNameComponent} = useContext(MenuContext);
    const [profs,setProfs] = useState([]);
    const [selectedProfs,setSelectedProfs] = useState(null);
    const [fecha,setFecha] = useState('');
    const [mode, setMode] = useState("default");
    const calendarRef = useRef(null);
    const [resources, setResources] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [eventProfs, setEventProfs] = useState([]);

     

    async function getProfs() { const rsp = await GeneralRequest('ava_profss', 'GET', {}); return rsp }
    async function getScheduleProfs(objSend) { const rsp = await GeneralRequest('pro_scheduler', 'POST', objSend); return rsp }

    useEffect(() => {
        let info = getProfs();
        info.then((data) => { 
          let { RSP } = data; setProfs(RSP);
        });
    },[]);

    function searcInfo(){
        setShowCalendar(false);
       if(fecha !== '' && selectedProfs !== null && selectedProfs.length > 0){
          let n_arr = selectedProfs.map((item)=>{ return item.value});
          let obj_send = {fecha,profesionales: JSON.stringify(n_arr)};
          let all = getScheduleProfs(obj_send);
          all.then((data)=>{
              // create resources
              let colors = ["#08c5bd","#58ab2d","#ecd5e3","#58ab2d","#ecd5e3","#08c5bd","#ecd5e3","#58ab2d","#ecd5e3","#08c5bd"];
              let rscs = [];
              let evnts = [];
              let k = 1;
              let { RSP } = data;
              for(var i = 0; i < RSP.length; i++){
                   let item = RSP[i];
                   if(item.data !== null && item.data.length > 0){
                     let dt = item.data[0];
                     let obj_rs = { admin_id: item.prof, title: `${dt.nombre_completo} - ${dt.razon_social}`,
                                    mobile: `start: ${dt.hora_inicial} fin: ${dt.hora_final}`, avatar: `${API_BASE}images/profiles/${dt.user_img}`,
                                    color: colors[i]};
                     rscs.push(obj_rs);
                     // turns
                     if(dt.turnos !== null && dt.turnos.length > 0){
                        for(var j = 0; j < dt.turnos.length; j++){
                             let iturn = dt.turnos[j];
                             let obj_trn = { event_id: k, title: `${iturn.nombre_cliente} - ${iturn.nombre_servicio} - ${iturn.precio}`, start: new Date(`${iturn.fecha} ${iturn.hora_inicial}`), end: new Date(`${iturn.fecha} ${iturn.hora_final}`), admin_id: item.prof};
                             evnts.push(obj_trn);
                             k++;
                        }
                     }
                     // rest
                     if(dt.descansos !== null && dt.descansos.length > 0){
                        for(var d = 0; d < dt.descansos.length; d++){
                            let desca = dt.descansos[d];
                            let obj_desca = { event_id: k, title: desca.title, start: new Date(`${fecha} ${desca.hora_inicial}`), end: new Date(`${fecha} ${desca.hora_final}`), admin_id: item.prof};
                            evnts.push(obj_desca);
                            k++;
                       }
                     }
                     // block
                     if(dt.bloqueos !== null && dt.bloqueos.length > 0){
                        for(var b = 0; b < dt.bloqueos.length; b++){
                            let bloq = dt.bloqueos[b];
                            let obj_bloq = { event_id: k, title: bloq.title, start: new Date(`${fecha} ${bloq.hora_inicial}`), end: new Date(`${fecha} ${bloq.hora_final}`), admin_id: item.prof};
                            evnts.push(obj_bloq);
                            k++;
                       }
                     }

                   }
              }
              setResources(rscs);
              setEventProfs(evnts);
              setShowCalendar(true);
          });
       }else{
          alert('Los campos son requeridos');
       }
       
    }

    return (
       <>
         <br/>
          <Row>
            <Col md={2} lg={2}>
                <input className='form-control' type='date' onChange={(e)=>setFecha(e.target.value)} />
            </Col>
            <Col md={4} lg={4}>
               <ReactSelect
                    isMulti
                    name="profs"
                    options={profs}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e)=>setSelectedProfs(e)}
                />
            </Col>
            <Col md={2} lg={2}>
              <Button variant='contained' color="primary" type='button' onClick={()=>searcInfo()}>Buscar</Button>
            </Col>
          </Row>
            <br/>
          <Row>
            <Col>
            { showCalendar && <Fragment>
                    <div style={{ textAlign: "center" }}>
                        <span>Resource View Mode: </span>
                        <Button
                        color={mode === "default" ? "primary" : "inherit"}
                        variant={mode === "default" ? "contained" : "text"}
                        size="small"
                        onClick={() => {
                            setMode("default");
                            calendarRef.current?.scheduler?.handleState(
                            "default",
                            "resourceViewMode"
                            );
                        }}
                        >
                        Default
                        </Button>
                        <Button
                        color={mode === "tabs" ? "primary" : "inherit"}
                        variant={mode === "tabs" ? "contained" : "text"}
                        size="small"
                        onClick={() => {
                            setMode("tabs");
                            calendarRef.current?.scheduler?.handleState(
                            "tabs",
                            "resourceViewMode"
                            );
                        }}
                        >
                        Tabs
                        </Button>
                    </div>

                    <Scheduler
                        ref={calendarRef}
                        events={eventProfs}
                        resources={resources}
                        editable={false}
                        deletable={false}
                        loading={!showCalendar}
                        day={{
                            startHour: 7, 
                            endHour: 23, 
                            step: 60,
                        }}
                        week={{
                            startHour: 7, 
                            endHour: 23, 
                            step: 60,
                        }}
                        resourceFields={{
                                idField: "admin_id",
                                textField: "title",
                                subTextField: "mobile",
                                avatarField: "avatar",
                                colorField: "color"
                        }}
                        viewerExtraComponent={(fields, event) => {
                        return (
                            <div>
                            {fields.map((field, i) => {
                                if (field.name === "admin_id") {
                                const admin = field.options.find(
                                    (fe) => fe.id === event.admin_id
                                );
                                return (
                                    <Typography
                                    key={i}
                                    style={{ display: "flex", alignItems: "center" }}
                                    color="textSecondary"
                                    variant="caption"
                                    noWrap
                                    >
                                    <PersonRoundedIcon /> {admin.text}
                                    </Typography>
                                );
                                } else {
                                return "";
                                }
                            })}
                            </div>
                        );
                        }}
                    />
                    
                    </Fragment>  }        
            </Col>
          </Row>
        </>);
}