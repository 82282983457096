import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import '../../assets/css/pages/auth.css';
import logo from '../../assets/images/logo/logo-ulala.png';
import bgk from '../../assets/images/img-principal.png';
import { Image,Form,Button} from 'react-bootstrap';
import { reqtsApiForm } from '../../config/utils';
import Alert from '../common/alert';


async function loginUser(credentials) {
    return reqtsApiForm('chwpanel', false, 'POST', {us_log: credentials.username, secret: credentials.password}, data => data, error => error)
       .then(resp => resp)
       .catch( 
        err => {
          console.log(err);
        }
      );
}

export default  function  Login({ setToken }) {
   
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [errorLogin, setErrorLogin] = useState(false);

    const handleSubmit = async(e) => {
        e.preventDefault();

        const rspLogin = await loginUser({
            username,
            password
        });

        console.log('rsp',rspLogin);
        
        if(rspLogin.STATUS){
            setToken(rspLogin);
        }else{
            setErrorLogin(!errorLogin)
        }
    }

    return(
        <>
        <Alert
                    show={errorLogin}
                    onHide={setErrorLogin}
                    titulo='Warning!'
                    contenido="Wrong username or password"
                    tipo="error"
                />
        <div id='auth'>
          <div className="row h-100">
            <div className="col-lg-5 col-12">
                <div className='col-lg-12 col-md-12' id="auth-left" >
                    <div className="auth-logo">
                           <Image src={logo} alt="Logo" style={{width:'80%',height:'80%'}} />
                    </div>
                    <div>
                        <h1 className="auth-title">Log in</h1>
                        <p className="auth-subtitle mb-5">Log in with your data</p>
                        <Form onSubmit={handleSubmit}>
                            <div className="form-group position-relative has-icon-left mb-4">
                                <input type="text" value={username} onChange={e => setUserName(e.target.value)} class="form-control form-control-xl" placeholder="Username" required/>
                                <div className="form-control-icon">
                                    <i className="bi bi-person"></i>
                                </div>
                            </div>
                            <div className="form-group position-relative has-icon-left mb-4">
                                <input type="password" value={password} onChange={e => setPassword(e.target.value)} className="form-control form-control-xl" placeholder="Password" required/>
                                <div className="form-control-icon">
                                    <i className="bi bi-shield-lock"></i>
                                </div>
                            </div>
                            <Button  type='submit' style={{backgroundColor:'#1E1E2D'}} className="btn btn-primary btn-block btn-lg mt-5">Log in</Button>
                        </Form>
                    </div> 
                </div>
            </div>
            <div className="col-lg-7 col-md-7 d-none d-lg-block" style={{backgroundColor:'#070C18'}}>
                <div id="auth-right">
                    <Image src={bgk} className='img-fluid' />
                </div>
            </div>
        </div>
        </div>
        </>
    );

}
Login.propTypes = {
    setToken: PropTypes.func.isRequired
};