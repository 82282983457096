import { createContext, useState, useEffect } from "react"
export const MenuContext = createContext();

                
const MenuProvider = ({children}) => {
    // true -> dark, false -> light 
    const [colorTheme, setColorTheme] = useState(true);
    const [nameComponent,setNameComponent] = useState('');
    const [slideActive,setSlideActive] = useState(true); 
    
    return (
        <MenuContext.Provider
            value={{ 
                colorTheme,
                setColorTheme,
                nameComponent,
                setNameComponent,
                slideActive,
                setSlideActive
            }}
        >
            {children}
        </MenuContext.Provider>
    )
}

export default MenuProvider
