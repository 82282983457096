import Login from './components/auth/login';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import useToken from './components/auth/useToken';
import './assets/css/main/app.css';
import './assets/css/main/app-dark.css';
import Menu from './components/home/menu';
import MenuProvider from './context/MenuContext';
import SchedulerProf from './components/schedule/schedule';

function App() {
  const { token, setToken } = useToken();
 
  if (!token) {
  return (
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Login setToken={setToken} />} />
          </Routes>
        </BrowserRouter>
  );
  }else{
    return (
      <div>
        <MenuProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Menu />} >
            <Route path="scheduler" element={<SchedulerProf/>} />
            </Route>
          </Routes>
        </BrowserRouter>
        </MenuProvider>
      </div>
    );
  }
}

export default App;
