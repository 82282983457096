import { useState } from 'react';
import { getVarSsn, setLogin } from '../../config/utils';

export default function useToken() {
  const getToken = () => {
    const userToken = getVarSsn();
    return userToken?.ATOLAND;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    setLogin(userToken);
    setToken(userToken.ATOLAND);
  };

  return {
    setToken: saveToken,
    token
  }
}